// src/services/api.js

import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: '/api'
});

const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return token ? { 'Authorization': `Bearer ${token}` } : {};
};

export const submitIdea = async (content) => {
    try {
        const response = await fetch('/api/ideas/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...getAuthHeaders()
            },
            body: JSON.stringify({ content })
        });

        if (!response.ok) {
            const error = await response.json();
            if (response.status === 401) {
                throw new Error('UNAUTHORIZED');
            }
            throw new Error(error.detail || 'Failed to submit idea');
        }

        return await response.json();
    } catch (error) {
        console.error('Failed to submit idea:', error);
        throw error;
    }
};

export const getProgress = async (ideaId) => {
    const response = await axiosInstance.get(`/progress/${ideaId}`);
    return response.data;
};

export const api = {
    submitIdea,
    getProgress,
};