// frontend/src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#5CFFE7', // The bright turquoise from the logo
      contrastText: '#1A1A24',
    },
    secondary: {
      main: '#5CFFE7',
    },
    background: {
      default: '#1A1A24', // Dark background color
      paper: '#242433',   // Slightly lighter for cards/papers
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B4B4B4',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
          padding: '10px 24px',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#4DEBD4',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#3A3A4A',
            },
            '&:hover fieldset': {
              borderColor: '#5CFFE7',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#5CFFE7',
            },
          },
        },
      },
    },
  },
});

export default theme;