import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Stack,
  Tabs,
  Tab,
  CircularProgress,
  Button,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { formatCurrency } from '../utils/format';
import DeleteIcon from '@mui/icons-material/Delete';

const getScore = (result) => {
  if (!result) return 0;
  const scoreLine = result.split('\n').find(line => line.includes('Hatoi Score:'));
  if (!scoreLine) return 0;
  const score = parseInt(scoreLine.split(':')[1].trim());
  return score / 10;
};

function Dashboard() {
  const [tab, setTab] = useState(0);
  const [userIdeas, setUserIdeas] = useState([]);
  const [scrapedIdeas, setScrapedIdeas] = useState([]);
  const [allUserIdeas, setAllUserIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [userIdeasSort, setUserIdeasSort] = useState('score');
  const [scrapedIdeasSort, setScrapedIdeasSort] = useState('score');
  const [allIdeasSort, setAllIdeasSort] = useState('score');
  const [scrapedSummary, setScrapedSummary] = useState(null);
  const [isGeneratingScrapedSummary, setIsGeneratingScrapedSummary] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  }, [isAuthenticated, navigate]);

  const fetchIdeas = async () => {
    if (!isAuthenticated) {
      return;
    }

    setLoading(true);
    try {
      // Always fetch user's own ideas
      const userResponse = await fetch(`/api/ideas/user/${user.id}`);
      if (userResponse.ok) {
        const userData = await userResponse.json();
        setUserIdeas(userData);
      }

      // Only fetch admin data if user.id === 1
      if (user.id === 1) {
        const scrapedResponse = await fetch('/api/ideas/scraped');
        if (scrapedResponse.ok) {
          const scrapedData = await scrapedResponse.json();
          setScrapedIdeas(scrapedData);
        }

        const allIdeasResponse = await fetch('/api/ideas/all');
        if (allIdeasResponse.ok) {
          const allIdeasData = await allIdeasResponse.json();
          setAllUserIdeas(allIdeasData);
        }
      }
    } catch (error) {
      console.error('Error fetching ideas:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIdeas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (user?.id !== 1 && (tab === 1 || tab === 2)) {
      setTab(0);
    }
  }, [user, tab]);

  const handleDelete = async (ideaId, isScraped = false) => {
    const message = isScraped 
      ? 'Are you sure you want to delete this scraped idea? This action cannot be undone.'
      : 'Are you sure you want to delete this idea? This action cannot be undone.';

    if (!window.confirm(message)) {
      return;
    }

    try {
      const response = await fetch(`/api/ideas/${ideaId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Refresh all ideas after successful deletion
        await fetchIdeas();
      } else {
        const data = await response.json();
        alert(data.detail || 'Failed to delete idea');
      }
    } catch (error) {
      console.error('Error deleting idea:', error);
      alert('Error deleting idea');
    }
  };

  const sortIdeas = (ideas, sortBy) => {
    if (!ideas) return [];
    
    const sortedIdeas = [...ideas];
    switch (sortBy) {
      case 'score':
        return sortedIdeas.sort((a, b) => {
          const scoreA = getScore(a.result) || 0;
          const scoreB = getScore(b.result) || 0;
          if (scoreB !== scoreA) return scoreB - scoreA;
          // Secondary sort by content if scores are equal
          return a.content.localeCompare(b.content);
        });
      case 'alpha':
        return sortedIdeas.sort((a, b) => 
          a.content.localeCompare(b.content)
        );
      case 'newest':
      default:
        return sortedIdeas.sort((a, b) => {
          const idA = a.idea_id.toLowerCase();
          const idB = b.idea_id.toLowerCase();
          return idB.localeCompare(idA);
        });
    }
  };

  const sortMenuItems = [
    { value: 'score', label: 'Highest Score' },
    { value: 'alpha', label: 'Alphabetical' },
    { value: 'newest', label: 'Most Recent' }
  ];

  const IdeaCard = ({ idea }) => {
    const score = getScore(idea.result);
    const getScoreColor = (score) => {
      if (score >= 8) return 'success';
      if (score >= 6) return 'warning';
      return 'error';
    };

    const scoreColorScheme = {
      success: {
        border: '#2e7d32', // darker green
        bg: 'rgba(46, 125, 50, 0.1)',
        text: '#1b5e20'  // even darker green for text
      },
      warning: {
        border: '#ed6c02', // darker orange
        bg: 'rgba(237, 108, 2, 0.1)',
        text: '#9a4800'  // darker orange for text
      },
      error: {
        border: '#d32f2f', // darker red
        bg: 'rgba(211, 47, 47, 0.1)',
        text: '#9a0036'  // darker red for text
      }
    };

    const colorScheme = idea.result ? scoreColorScheme[getScoreColor(score)] : {
      border: '#bdbdbd',
      bg: 'rgba(189, 189, 189, 0.1)',
      text: '#757575'
    };

    return (
      <Card sx={{ 
        mb: 2,
        borderLeft: 4,
        borderColor: colorScheme.border
      }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {idea.content?.substring(0, 100)}...
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography color="textSecondary">
              Status: {idea.status}
            </Typography>
            {idea.result && (
              <Typography 
                sx={{ 
                  fontWeight: 'bold',
                  color: colorScheme.text,
                  bgcolor: colorScheme.bg,
                  px: 2,
                  py: 0.5,
                  borderRadius: 1,
                  border: 1,
                  borderColor: colorScheme.border
                }}
              >
                Score: {score * 10}/100
              </Typography>
            )}
          </Box>
          {idea.cost !== undefined && (
            <Typography color="textSecondary" sx={{ mt: 1 }}>
              Cost: {formatCurrency(idea.cost)}
            </Typography>
          )}
          {idea.user_email && (
            <Typography color="textSecondary" sx={{ mt: 1 }}>
              User: {idea.user_email}
            </Typography>
          )}
          {idea.source && (
            <Typography color="textSecondary" sx={{ mt: 1 }}>
              Source: {idea.source}
            </Typography>
          )}
          {idea.result && (
            <Typography 
              variant="body2" 
              sx={{ 
                mt: 2, 
                mb: 2,
                p: 2,
                bgcolor: '#f5f5f5',
                borderRadius: 1,
                border: 1,
                borderColor: 'grey.300',
                color: 'rgba(0, 0, 0, 0.87)',
                whiteSpace: 'pre-line',
                fontFamily: 'inherit',
                fontSize: '0.875rem',
                lineHeight: 1.5
              }}
            >
              {idea.result.substring(0, 200)}...
            </Typography>
          )}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            mt: 2,
            borderTop: 1,
            borderColor: 'divider',
            pt: 2
          }}>
            <IconButton
              onClick={() => handleDelete(idea.idea_id, Boolean(idea.source))}
              color="error"
              size="small"
              sx={{ mr: 1 }}
              title="Delete idea"
            >
              <DeleteIcon />
            </IconButton>
            <Button
              component={Link}
              to={`/progress/${idea.idea_id}`}
              variant="contained"
              color="primary"
              size="small"
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: theme => theme.palette.primary.dark,
                }
              }}
            >
              View Details
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const UserIdeasSection = () => (
    <>
      <Box sx={{ mb: 2 }}>
        <FormControl size="small" fullWidth>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={userIdeasSort}
            label="Sort By"
            onChange={(e) => setUserIdeasSort(e.target.value)}
          >
            {sortMenuItems.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {sortIdeas(userIdeas, userIdeasSort).map(idea => (
        <Box key={idea.idea_id}>
          <IdeaCard idea={idea} />
        </Box>
      ))}
    </>
  );

  const ScrapedIdeasSection = () => (
    <>
      {scrapedSummary && (
        <Box sx={{ mb: 4, p: 3, bgcolor: 'rgba(255, 215, 0, 0.1)', borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Ideas Summary
          </Typography>
          <Typography>{scrapedSummary}</Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          onClick={async () => {
            setIsGeneratingScrapedSummary(true);
            try {
              const response = await fetch('/api/ideas/generate-summary');
              if (response.ok) {
                const data = await response.json();
                setScrapedSummary(data.summary);
              }
            } catch (error) {
              console.error('Error generating summary:', error);
            } finally {
              setIsGeneratingScrapedSummary(false);
            }
          }}
          disabled={isGeneratingScrapedSummary}
          startIcon={isGeneratingScrapedSummary ? <CircularProgress size={20} /> : null}
        >
          {isGeneratingScrapedSummary ? 'Generating Summary...' : 'Generate Ideas Summary'}
        </Button>
        <FormControl size="small" fullWidth>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={scrapedIdeasSort}
            label="Sort By"
            onChange={(e) => setScrapedIdeasSort(e.target.value)}
          >
            {sortMenuItems.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {sortIdeas(scrapedIdeas, scrapedIdeasSort).map(idea => (
        <Box key={idea.idea_id}>
          <IdeaCard idea={idea} />
        </Box>
      ))}
    </>
  );

  const AllIdeasSection = () => (
    <>
      <Box sx={{ mb: 2 }}>
        <FormControl size="small" fullWidth>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={allIdeasSort}
            label="Sort By"
            onChange={(e) => setAllIdeasSort(e.target.value)}
          >
            {sortMenuItems.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {sortIdeas(allUserIdeas, allIdeasSort).map(idea => (
        <Box key={idea.idea_id}>
          <IdeaCard idea={idea} />
        </Box>
      ))}
    </>
  );

  if (loading) {
    return (
      <Container sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        {tab === 0 ? 'My Ideas' : 
         tab === 1 ? 'Scraped Ideas' : 
         'All Users\' Ideas'}
      </Typography>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={tab} 
          onChange={(e, newValue) => setTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="My Ideas" />
          {user?.id === 1 && <Tab label="Scraped Ideas" />}
          {user?.id === 1 && <Tab label="All Users' Ideas" />}
        </Tabs>
      </Box>

      <Stack spacing={2}>
        {tab === 0 && (
          <>
            <UserIdeasSection />
            {userIdeas.length === 0 && (
              <Box>
                <Typography align="center">No ideas submitted yet.</Typography>
              </Box>
            )}
          </>
        )}

        {tab === 1 && user?.id === 1 && (
          <ScrapedIdeasSection />
        )}

        {tab === 2 && user?.id === 1 && (
          <>
            <AllIdeasSection />
            {allUserIdeas.length === 0 && (
              <Box>
                <Typography align="center">No ideas from other users.</Typography>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}

export default Dashboard; 