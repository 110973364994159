import React, { useEffect, useState } from 'react';
import { Box, Typography, LinearProgress, Paper, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../contexts/AuthContext';

const PROGRESS_INTERVAL = 100; // Reduce to 100ms
const PROGRESS_INCREMENT = 1; // Increase by 1% each time

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return token ? { 'Authorization': `Bearer ${token}` } : {};
};

function IdeaProgress() {
  const { ideaId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fakeProgress, setFakeProgress] = useState(0);
  const [error, setError] = useState(null);

  // Add back button handler
  const handleBack = () => {
    navigate('/dashboard');
  };

  // Helper function for getting score
  const getScore = (result) => {
    if (!result) return 0;
    const scoreLine = result.split('\n').find(line => line.includes('Hatoi Score:'));
    if (!scoreLine) return 0;
    const score = parseInt(scoreLine.split(':')[1].trim());
    return score / 10;
  };

  // Fetch progress effect
  useEffect(() => {
    if (!ideaId) {
      setError('No idea ID provided');
      setLoading(false);
      return;
    }

    const fetchProgress = async () => {
      try {
        console.log('Fetching progress for idea:', ideaId);
        const response = await fetch(`/api/ideas/progress/${ideaId}`);
        if (response.ok) {
          const data = await response.json();
          console.log('Received idea data:', data);
          setIdea(data);
          if (data.status === 'Completed') {
            setFakeProgress(100);
          }
        } else {
          const errorData = await response.json();
          setError(`Error: ${errorData.detail || 'Failed to fetch idea'}`);
        }
      } catch (error) {
        console.error('Error fetching idea progress:', error);
        setError(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProgress();
    const interval = setInterval(fetchProgress, 5000);

    return () => clearInterval(interval);
  }, [ideaId]);

  // Fake progress effect
  useEffect(() => {
    let timer;
    if (idea?.status !== 'Completed') {
      timer = setInterval(() => {
        setFakeProgress(oldProgress => {
          if (oldProgress < 90) {
            return oldProgress + PROGRESS_INCREMENT;
          }
          return oldProgress;
        });
      }, PROGRESS_INTERVAL);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [idea?.status]);

  // Add new effect to update user_id
  useEffect(() => {
    if (isAuthenticated && ideaId) {
      const updateIdeaUser = async () => {
        try {
          const response = await fetch(`/api/ideas/${ideaId}/user`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              ...getAuthHeaders()
            }
          });
          
          if (!response.ok) {
            console.error('Failed to update idea user');
          }
        } catch (error) {
          console.error('Error updating idea user:', error);
        }
      };

      updateIdeaUser();
    }
  }, [isAuthenticated, ideaId]);

  // Show error if there is one
  if (error) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  // Render loading state
  if (loading || !idea) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <Typography align="center" gutterBottom>
          Loading idea progress...
        </Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Button
        onClick={handleBack}
        startIcon={<ArrowBackIcon />}
        sx={{ mb: 3 }}
        variant="outlined"
      >
        Back to Dashboard
      </Button>

      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          mb: 3, 
          bgcolor: 'rgba(25, 118, 210, 0.04)',
          borderRadius: 2,
          border: '1px solid rgba(25, 118, 210, 0.1)'
        }}
      >
        <Typography variant="h6" gutterBottom color="primary">
          Your Idea
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {idea.content}
        </Typography>
      </Paper>

      {idea.status !== 'Completed' && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Processing Your Idea...
          </Typography>
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress 
              variant="determinate" 
              value={Math.min(fakeProgress, 100)} 
            />
            <Typography 
              variant="body2" 
              color="text.secondary" 
              align="center" 
              sx={{ mt: 1 }}
            >
              {Math.round(fakeProgress)}%
            </Typography>
          </Box>
        </Paper>
      )}

      {idea.result && (
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            mb: 3,
            bgcolor: 'rgba(76, 175, 80, 0.04)',
            borderRadius: 2,
            border: '1px solid rgba(76, 175, 80, 0.1)'
          }}
        >
          <Typography variant="h6" gutterBottom color="success.main">
            Analysis Results
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            {idea.result}
          </Typography>
        </Paper>
      )}
    </Box>
  );
}

export default IdeaProgress;